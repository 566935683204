import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion, Container, Image } from "react-bootstrap";
import CommonSection from "../components/CommonSection";
import ClientLogoBanner from "../components/ClientLogoBanner";
import ColumnSlider from "../components/ColumnSlider/ColumnSlider";
import ButtonBanner from "../components/ButtonBanner";
import "../styles/auto.scss";
import StatColumn from "../components/StatColumn";
import CaseStudy from "../sections/homepage/CaseStudy";
import IconColumn from "../components/IconColumn";
import Testimonials from "../sections/homepage/Testimonials";
import Blogs from "../sections/homepage/Blogs";
import AccordionSection from "../components/AccordionSection";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";

const AutoLandingPage = ({ data }) => {
  const caseStudies = [
    {
      image: "../../images/casestudy/cars24.webp",
      title: "Stunning 360 car visuals in minutes",
      footer: "",
      link: "/cars24",
    },
    {
      image: "../../images/casestudy/gigacars.webp",
      title: "Studio grade imaging solution for Giga Cars",
      footer: "",
      link: "/giga-cars",
    },
    {
      image: "../../images/casestudy/spinny.webp",
      title: "Studio grade images & 360 car visuals",
      footer: "",
      link: "/spinny",
    },
  ];
  let logoList = [
    "../../images/trustedclients/cars24-1.svg",
    "../../images/trustedclients/spinny1.svg",
    "../../images/trustedclients/giga.svg",
    "../../images/trustedclients/featherlite1.svg",
    "../../images/trustedclients/ashirvad1.svg",
    "../../images/trustedclients/wakefit1.svg",
  ];
  return (
    <Layout>
      <Helmet>
        <title>
          HelloAR: Create Car 3d view | Car 3D View| Background Removal
        </title>
        <meta
          name="description"
          content="Our proprietary software automates background removal cfrom cars, create car 360 view in seconds. "
        />
        <meta name="keywords" content="car 360 view, car 3d view, 360 car" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta property="og:image" content="../../images/auto/car_360.jpg" />
      </Helmet>

      <Container fluid className="automobile">
        <div className="spacer"></div>
        <div className="spacer d-xl-none"></div>
        <div className="spacer-sm"></div>
        <CommonSection
          title="Create professional car images in seconds"
          sub="Create a studio-grade gallery of images and 360-degree videos in minutes. Win trust, create transparency, reduce errors, and learn shoppers behavior all from one platform and achieve 2x sales."
          buttonText="REQUEST A DEMO"
        >
          <VideoPlayer
            videoUrl="../../videos/360-auto.mp4"
            videoThumbnail="../../images/thumbnail/automotive.webp"
          />
        </CommonSection>
        <div className="spacer-sm"></div>
        {/* <CaseStudy caseStudies={caseStudies} /> */}
        {/* <ClientLogoBanner logoList={logoList} /> */}
        <div className="spacer"></div>
        <IconColumn
          header="HelloAR platform empowers you"
          sub="Our AI driven platform is designed to provide you with one stop visual solution. Now scale at speed with HelloAR"
          items={[
            {
              title: "Create professional images in under 10 minutes",
              sub: "",
              icon: "../icons/stamp.svg",
            },
            {
              title: "Engage better with users",
              sub: "",
              icon: "../icons/infinity.svg",
            },
            {
              title: "Convert more",
              sub: "",
              icon: "../icons/heart.svg",
            },
          ]}
        />
        <div className="spacer"></div>
        <ColumnSlider
          header="Create professional images in under 10 minutes"
          items={[
            {
              title: "Image background removal",
              sub: "Our automated AI software removes images from your car instantly",
              icon: "../icons/cut.svg",
            },
            {
              title: "Add professional background templates",
              sub: "Choose from hundreds of templates for studio-quality images in seconds",
              icon: "../icons/stamp.svg",
            },
            {
              title: "360 degree image capture (interior & exterior)",
              sub: "With the HelloAR app click thousands of car images in every angle within seconds.",
              icon: "../icons/360.svg",
            },
            {
              title: "Damage tagging",
              sub: "Never mix up car data again. Tag damages clearly for lower sales return.",
              icon: "../icons/tag.svg",
            },
          ]}
          buttonText="REQUEST A DEMO"
        >
          <Image src="/images/auto/bgremoval.jpg" fluid />
        </ColumnSlider>
        <div className="spacer"></div>
        <ColumnSlider
          header="Engage better with users"
          items={[
            {
              title: "Instant 360-degree turnaround videos",
              sub: "With our easy integration, 360-degree turnaround videos are generated instantly.",
              icon: "../icons/360.svg",
            },
            {
              title: "Create a feature tour",
              sub: "Showcase all the car features up closely for users to experience the car better",
              icon: "../icons/location.svg",
            },
            {
              title: "Seamless user experience",
              sub: "Delight your users with the honest experience",
              icon: "../icons/infinity.svg",
            },
          ]}
          buttonText="REQUEST A DEMO"
          inverted
        >
          <VideoPlayer
            videoUrl="../../videos/car360.mp4"
            videoThumbnail="../../images/thumbnail/automotive360.webp"
          />
        </ColumnSlider>
        <div className="spacer"></div>
        <ColumnSlider
          header="Convert more"
          items={[
            {
              title: "Get shopper’s insights",
              sub: "Have handy information on cars and models that are being most checked by the website visitors.",
              icon: "../icons/heart.svg",
            },
          ]}
          buttonText="REQUEST A DEMO"
        >
          <Image src="/images/auto/cars-360.jpg" fluid />
        </ColumnSlider>
        <div className="spacer"></div>
        <StatColumn
          title="Why create professional 360 degree images?"
          items={[
            {
              stat: "46%",
              info: "Upto 46% increase in total vehicle sales​",
            },
            {
              stat: "61%",
              info: "Upto 61% reduced time spent on sale​",
            },
            {
              stat: "46%",
              info: "Receive up to 46% more qualified leads",
            },
            {
              stat: "20x",
              info: "Up to 20x more website visitors",
            },
            // {
            //   stat: "47%",
            //   info: "Upto 47% Expanded customer reach/market​",
            // },
          ]}
        />
        <div className="spacer"></div>
        <ClientLogoBanner logoList={logoList} />
        {/* <CaseStudy /> */}
        <div className="spacer"></div>
        {/* <div className="spacer-sm"></div> */}
        <ButtonBanner buttonTitle="REQUEST A DEMO" buttonUrl="/contact-us">
          <h1 className="h1 text-center text-light px-3">
            One stop solution.
            <br />
            Zero upfront cost. Pay as you go.
          </h1>
        </ButtonBanner>
        <div className="spacer"></div>
        <AccordionSection
          title="HelloAR advantage"
          items={[
            {
              title: "AI-generated studio grade photos",
              sub: "Automate background removal from car images in seconds.",
              image: "../../images/auto/autoaccordion.png",
            },
            {
              title: "Scale",
              sub: "Bulk edit Images and Fine Tune your gallery in seconds.",
              image: "../../images/auto/autoaccordion.png",
            },
            {
              title: "Get cars online in minutes",
              sub: "Create 360 walkaround videos in minutes. All you need is an iOS phone.",
              image: "../../images/auto/autoaccordion.png",
            },
            {
              title: "Easy and quick website integration",
              sub: "Our software integrates on your website seamlessly.",
              image: "../../images/auto/autoaccordion.png",
            },
            {
              title: "All in one dashboard",
              sub: "Keep track of all your onsite shoots. View, manage, edit and download all of your car images in a single click.",
              image: "../../images/auto/autoaccordion.png",
            },
            {
              title: "God speed",
              sub: "Directly upload or download thousands of images from our cloud servers in seconds.",
              image: "../../images/auto/autoaccordion.png",
            },
            {
              title: "End to end solution",
              sub: "We provide a complete solution. From image editing to creating 360 car views, we have you covered.",
              image: "../../images/auto/autoaccordion.png",
            },
            {
              title: "24/7 support",
              sub: "We’ve a dedicated team to help you with all your queries.",
              image: "../../images/auto/autoaccordion.png",
            },
          ]}
          mainIndex={3}
        />
        <div className="spacer"></div>
        <div className="spacer-sm"></div>
        <Testimonials />
        <div className="spacer"></div>
        {/* <div className="spacer-sm"></div> */}
        {/* <Blogs /> */}
        {/* <div className="spacer"></div> */}
        {/* <div className="spacer-sm"></div> */}
      </Container>
    </Layout>
  );
};

// export const imgs = graphql`
//   query {
//     cars24temp: file(relativePath: { eq: "16x9placeholder.png" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     bgRemoval: file(relativePath: { eq: "bgremoval.jpg" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     convertMore: file(relativePath: { eq: "auto/cars-360.webp" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;

export default AutoLandingPage;
