import { Link } from "gatsby";
import React, { useRef, useState } from "react";
import "./ColumnSlider.scss";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-elastic-carousel";

interface ColumnSliderItem {
  title: string;
  sub: string;
  icon: string;
}

interface IProps {
  header: string;
  items: ColumnSliderItem[];
  buttonText?: string;
  inverted?: boolean;
}

const ColumnSlider: React.FC<IProps> = ({
  header,
  items,
  buttonText,
  inverted,
  children,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const carousel = useRef<any>();

  const goToSlide = (index: number) => {
    setSelectedIndex(index);
    carousel.current.goTo(index);
  };

  return (
    <div className="columnSliderContainer">
      <Container>
        <h1 className="h1 text-center">{header}</h1>
        <div className="spacer-sm"></div>
        <Row className={`${inverted ? "reverse" : ""} align-items-center`}>
          <Col md={{ span: 6 }} className={`${inverted && "mb-5 mb-md-0"}`}>
            {children}
          </Col>
          <Col
            md={{ span: 6 }}
            className={`content ${inverted ? "" : "mt-5 mt-md-0"}`}
          >
            <div className="circles d-flex align-items-center">
              {items.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    selectedIndex == index ? "active" : ""
                  } circle d-flex align-items-center justify-content-center`}
                  onClick={() => goToSlide(index)}
                  style={{ cursor: "pointer" }}
                >
                  <img src={item.icon} alt={item.title} />
                </div>
              ))}
            </div>
            <Carousel
              ref={carousel}
              pagination={false}
              showArrows={false}
              onPrevEnd={(obj, index) => {
                setSelectedIndex(index);
              }}
              onNextEnd={(obj, index) => {
                setSelectedIndex(index);
              }}
              enableAutoPlay
            >
              {items.map((item, index) => (
                <div key={index} className="item">
                  <h2 className="font-weight-bold">{item.title}</h2>
                  <h4>{item.sub}</h4>
                </div>
              ))}
            </Carousel>
            {buttonText && buttonText.length > 0 && (
              <div className="d-flex justify-content-center justify-content-lg-start">
                <Link to="/contact-us">
                  <div className="cta-demo">
                    <p className="mb-0 font-weight-bold text-light">
                      REQUEST A DEMO
                    </p>
                  </div>
                </Link>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ColumnSlider;
